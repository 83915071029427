import React from 'react'
import { useQueryParam, StringParam } from 'use-query-params'
import { useAuth0 } from 'utils/react-auth0-spa'
import { useTranslation } from 'react-i18next'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from 'components/Layout'
import {
  BackgroundImageContainer,
  TextContainer,
  AcademySection,
  HeaderTitle,
  ContentContainer,
  ImageContainer,
  AcademyTextContainer,
  FlexContainer,
  FlexColumn,
  FeatureSection,
  RowContainer,
  RightContainer,
  LeftContainer,
  FeaturesTextContainer,
  RightRow,
  LeftRow,
  RightRowContainer,
  FunctionsSection,
  IconContainer,
  FunctionsTextContainer,
  FunctionsImageContainer,
  TestimoniesSection,
  TestimoniesIconContainer,
  TestimoniesRow,
  RowImageContainer,
  RowTextContainer,
  RowReverse,
  RowReverseImageContainer,
  RowReverseTextContainer,
  CallToActionSection,
  CallToActionImageContainer,
  SubscriptionsContainer,
  Subscription,
  SubscriptionRow,
  SubscriptionColumnOne,
  SubscriptionColumnTwo,
  AppLinksContainer,
  StoreIconContainer,
  AppStoreText,
  PerfectAccentSection,
  PerfectAccentRow,
  PerfectAccentLeftContainer,
  PerfectAccentRightContainer,
  PerfectAccentTextContainer,
  PerfectAccentDownloadAppContainer,
  PerfectAccentInfoContainer,
  PerfectAccentAppRow,
  PerfectAccentColumnOne,
  PerfectAccentColumnTwo,
  AppStoreLinksContainer,
} from 'components/ProductComponents'
import Seo from 'components/Seo'
import Textbox from 'components/common/Textbox'
import Subscriptions from 'components/Subscriptions'
import SocialSideBar from 'components/SocialSideBar'
import SubscriptionPlans from 'components/Subscriptions/SubscriptionPlans'
import {
  FLUENTWORLDS_GOOGLE_PLAY_STORE_DOWNLOAD_LINK,
  FLUENTWORLDS_APPLE_APP_STORE_DOWNLOAD_LINK,
  PERFECTACCENT_GOOGLE_PLAY_STORE_DOWNLOAD_LINK,
  PERFECTACCENT_APPLE_APP_STORE_DOWNLOAD_LINK,
  FLUENTWORLDS_ACADEMY_LINK,
} from 'constants/product-links'
import { SPANISH } from 'utils/supportedLanguages'
import FWFunctions_en from 'images/png/products-fwfunctions-en.png'
import FWFunctions_es from 'images/png/products-fwfunctions-es.png'

const Products = () => {
  const { isAuthenticated } = useAuth0()
  const [affiliate] = useQueryParam('affiliate', StringParam)
  const { t, i18n } = useTranslation('products')

  return (
    <Layout>
      <Seo title={t('title')} description={t('description')} />
      <>
        <SocialSideBar />
        <BackgroundImageContainer>
          <TextContainer>
            <h1>"{t('thankGod')}"</h1>
            <h4>{t('englishAnywhere')}</h4>
          </TextContainer>
        </BackgroundImageContainer>
      </>
      <SubscriptionPlans>
        <h1>{isAuthenticated ? 'Scroll down' : t('signIn')}</h1>
        <h4>{t('englishAnywhere')}</h4>
        <Subscriptions />
      </SubscriptionPlans>
      <FeatureSection>
        <HeaderTitle name="fluentworlds">
          <h1>{t('fluentWorldsApp')}</h1>
        </HeaderTitle>
        <RowContainer>
          <RightContainer>
            <StaticImage
              src="../images/png/start_fwshowcase_250bk.png"
              alt="FluentWorlds"
            />
          </RightContainer>
          <LeftContainer>
            <FeaturesTextContainer>
              <h3>{t('mostCommonlyUsedWordsHeading')}</h3>
              <p>{t('mostCommonlyUsedWordsParagraph')}</p>
              <h3>{t('threeSkillLevelsHeading')}</h3>
              <p>{t('threeSkillLevelsParagraph')}</p>
              <h3>{t('practicalDictionaryHeading')}</h3>
              <p>{t('practicalDictionaryParagraph')}</p>
            </FeaturesTextContainer>
          </LeftContainer>
        </RowContainer>
        <RightRow>
          <LeftRow>
            <StaticImage
              src="../images/png/products_fwwhitephones.png"
              alt="FluentWorlds"
            />
          </LeftRow>
          <RightRowContainer>
            <FeaturesTextContainer>
              <h3>{t('realTimePronunciationHeading')}</h3>
              <p>{t('realTimePronunciationParagraph')}</p>
              <h3>{t('progressTrackerHeading')}</h3>
              <p>{t('progressTrackerParagraph')}</p>
              <h3>{t('realLifeSituationsHeading')}</h3>
              <p>{t('realLifeSituationsParagraph')}</p>
            </FeaturesTextContainer>
          </RightRowContainer>
        </RightRow>
      </FeatureSection>

      <FunctionsSection>
        <IconContainer>
          <StaticImage src="../images/png/downarrowlarge.png" alt="arrow" />
        </IconContainer>
        <FunctionsTextContainer>
          <h1>{t('features')}</h1>
        </FunctionsTextContainer>
        <FunctionsImageContainer>
          <img
            src={
              i18n.language === SPANISH.code ? FWFunctions_es : FWFunctions_en
            }
            alt="FluentWorlds"
          />
        </FunctionsImageContainer>
      </FunctionsSection>

      <TestimoniesSection>
        <TestimoniesIconContainer>
          <StaticImage src="../images/png/quoteicon.png" alt="quotes" />
        </TestimoniesIconContainer>
        <TestimoniesRow>
          <RowImageContainer>
            <StaticImage
              src="../images/jpg/products-girl-grass.jpg"
              alt="girl"
            />
          </RowImageContainer>
          <RowTextContainer>
            "{t('testimonyAna')}"<span>- Ana</span>
          </RowTextContainer>
        </TestimoniesRow>
        <RowReverse>
          <RowReverseImageContainer>
            <StaticImage
              src={'../images/jpg/products-man-phone.jpg'}
              alt="man"
            />
          </RowReverseImageContainer>
          <RowReverseTextContainer>
            "{t('testimonyLeonardo')}"<span>- Leonardo.</span>
          </RowReverseTextContainer>
        </RowReverse>
        <TestimoniesRow>
          <RowImageContainer>
            <StaticImage src="../images/jpg/products-man-trees.jpg" alt="man" />
          </RowImageContainer>
          <RowTextContainer>
            "{t('testimonyJuan')}"<span>- Juan</span>
          </RowTextContainer>
        </TestimoniesRow>
      </TestimoniesSection>

      <CallToActionSection>
        <HeaderTitle>
          <h1>{t('startUsingFluentWorlds')}</h1>
        </HeaderTitle>
        <CallToActionImageContainer>
          {affiliate === undefined && (
            <SubscriptionsContainer>
              <Subscription>
                <SubscriptionRow>
                  <SubscriptionColumnOne>
                    <StaticImage
                      src="../images/png/products-fw-app-logo.png"
                      alt="FluentWorlds App"
                    />
                  </SubscriptionColumnOne>
                  <SubscriptionColumnTwo>
                    <h3>FluentWorlds</h3>
                    <p>{t('descriptionLearnEnglish')}</p>
                  </SubscriptionColumnTwo>
                </SubscriptionRow>
                <AppLinksContainer>
                  <h5>{t('downloadOn')}</h5>
                  <StoreIconContainer>
                    <a
                      href={FLUENTWORLDS_GOOGLE_PLAY_STORE_DOWNLOAD_LINK}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <StaticImage
                        src="../images/png/google_play.png"
                        alt="google play"
                        height={30}
                      />
                    </a>
                    <AppStoreText>
                      <a
                        href={FLUENTWORLDS_GOOGLE_PLAY_STORE_DOWNLOAD_LINK}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Google Play
                      </a>
                    </AppStoreText>
                  </StoreIconContainer>
                  <StoreIconContainer>
                    <a
                      href={FLUENTWORLDS_APPLE_APP_STORE_DOWNLOAD_LINK}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <StaticImage
                        src="../images/png/apple.png"
                        alt="apple store"
                        height={30}
                      />
                    </a>

                    <AppStoreText>
                      <a
                        href={FLUENTWORLDS_APPLE_APP_STORE_DOWNLOAD_LINK}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        App Store
                      </a>
                    </AppStoreText>
                  </StoreIconContainer>
                </AppLinksContainer>
              </Subscription>
            </SubscriptionsContainer>
          )}
        </CallToActionImageContainer>
      </CallToActionSection>
      {affiliate && (
        <SubscriptionPlans>
          <h1>{isAuthenticated ? 'Scroll down' : 'Sign In'}</h1>
          <h4>{t('purchaseSubscription')}</h4>
          <Subscriptions />
        </SubscriptionPlans>
      )}

      <AcademySection>
        <HeaderTitle name="academy">
          <h1>{t('fluentWorldsAcademy')}</h1>
        </HeaderTitle>
        <ContentContainer>
          <ImageContainer>
            <StaticImage
              src="../images/jpg/products-fwacademypeople.jpg"
              alt="FluentWorlds Academy"
            />
          </ImageContainer>
          <AcademyTextContainer>
            <Textbox>
              <h2>{t('noLimitationsHeading')}</h2>
              {t('noLimitationsParagraph')}
              <h2>{t('immersiveEducationHeading')}</h2>
              {t('immersiveEducationParagraph')}
              <br></br>
              <br></br>
              <span>
                <a
                  href={FLUENTWORLDS_ACADEMY_LINK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('registerHere')}
                </a>
              </span>
            </Textbox>
          </AcademyTextContainer>
        </ContentContainer>

        <FlexContainer>
          <FlexColumn>
            <h1>1</h1>
            <h3>{t('learnEnglishHeading')}</h3>
            <p>{t('learnEnglishParagraph')}</p>
          </FlexColumn>
          <FlexColumn>
            <h1>2</h1>
            <h3>{t('americanInstructorsHeading')}</h3>
            <p>{t('americanInstructorsParagraph')}</p>
          </FlexColumn>
          <FlexColumn>
            <h1>3</h1>
            <h3>{t('acceleratedLearningHeading')}</h3>
            <p>{t('acceleratedLearningParagraph')}</p>
          </FlexColumn>
          <FlexColumn>
            <h1>4</h1>
            <h3>{t('effectiveLearningExperienceHeading')}</h3>
            <p>{t('effectiveLearningExperienceParagraph')}</p>
          </FlexColumn>
        </FlexContainer>
      </AcademySection>

      <PerfectAccentSection>
        <HeaderTitle name="perfectaccent">
          <h1>{t('perfectAccent')}</h1>
        </HeaderTitle>
        <PerfectAccentRow>
          <PerfectAccentRightContainer>
            <StaticImage
              src="../images/jpg/products-pa-2phones.jpg"
              alt="PerfectAccent"
            />
          </PerfectAccentRightContainer>
          <PerfectAccentLeftContainer>
            <PerfectAccentTextContainer>
              <h3>{t('personalizedVoicePrintHeading')}</h3>
              <p>{t('personalizedVoicePrintParagraph')}</p>
              <h3>{t('personalizedPhrasesHeading')}</h3>
              <p>{t('personalizedPhrasesParagraph')}</p>
              <h3>{t('industrySpecificHeading')}</h3>
              <p>{t('industrySpecificParagraph')}</p>
            </PerfectAccentTextContainer>
          </PerfectAccentLeftContainer>
        </PerfectAccentRow>
        {affiliate === undefined && (
          <PerfectAccentDownloadAppContainer>
            <PerfectAccentInfoContainer>
              <PerfectAccentAppRow>
                <PerfectAccentColumnTwo>
                  <h3>{t('PerfectAccent')}</h3>
                  <p>{t('perfectPronunciation')}</p>
                </PerfectAccentColumnTwo>
                <PerfectAccentColumnOne>
                  <StaticImage
                    src="../images/png/products-pa-app-logo.png"
                    alt="Perfect Accent App"
                  />
                </PerfectAccentColumnOne>
              </PerfectAccentAppRow>
              <AppStoreLinksContainer>
                <h5>{t('downloadOn')}</h5>
                <StoreIconContainer>
                  <a
                    href={PERFECTACCENT_GOOGLE_PLAY_STORE_DOWNLOAD_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StaticImage
                      src="../images/png/google_play.png"
                      alt="google play"
                      height={30}
                    />
                  </a>
                  <AppStoreText>
                    <a
                      href={PERFECTACCENT_GOOGLE_PLAY_STORE_DOWNLOAD_LINK}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Google Play
                    </a>
                  </AppStoreText>
                </StoreIconContainer>
                <StoreIconContainer>
                  <a
                    href={PERFECTACCENT_APPLE_APP_STORE_DOWNLOAD_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StaticImage
                      src="../images/png/apple.png"
                      alt="apple store"
                      height={30}
                    />
                  </a>
                  <AppStoreText>
                    <a
                      href={PERFECTACCENT_APPLE_APP_STORE_DOWNLOAD_LINK}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      App Store
                    </a>
                  </AppStoreText>
                </StoreIconContainer>
              </AppStoreLinksContainer>
            </PerfectAccentInfoContainer>
          </PerfectAccentDownloadAppContainer>
        )}
      </PerfectAccentSection>

      <SubscriptionPlans>
        <h1>{t('ourPlans')}</h1>
        <h4>{t('chooseSubscription')}</h4>
        <Subscriptions />
      </SubscriptionPlans>
    </Layout>
  )
}

export default Products
